<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                  label="Esemény"
              >
                <validation-provider
                    #default="{ errors }"
                    name="esemény"
                    rules="required"
                    vid="event"
                >
                  <b-form-input
                      v-model="formData.event"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label="Kezdő dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kezdő dátum"
                    rules="required"
                    vid="startDate"
                >
                  <flat-pickr
                      v-model="formData.startDate"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label="Befejező dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="befejező dátum"
                    rules="required"
                    vid="endDate"
                >
                  <flat-pickr
                      v-model="formData.endDate"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Érintett horgászhelyek"
              >
                <validation-provider
                    #default="{ errors }"
                    name="érintett horgászhelyek"
                    rules="required"
                    vid="fishingSpots"
                >
                  <v-select
                      v-model="formData.fishingSpots"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="fishingSpotOptions"
                      multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_reservationEvent/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BLink,
    BOverlay,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
    flatPickr
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      formData: {
        id: '',
        event: '',
        startDate: '',
        endDate: '',
        fishingSpots: [],
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      fishingSpotOptions: [],
      required,
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservationEvent', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {
        this.$store.dispatch('fetchFishingSpotsForSelect').then(fsResponse => {
          this.fishingSpotOptions = fsResponse
          const items = []

          Object.keys(entity.fishing_spots).forEach(key => {
            Object.keys(this.fishingSpotOptions).forEach(optionKey => {
              if (entity.fishing_spots[key].id === this.fishingSpotOptions[optionKey].value) {
                items.push(this.fishingSpotOptions[optionKey])
              }
            })
          })

          this.formData.fishingSpots = items;
        })

        this.formData.id = this.$route.params.id
        this.formData.event = entity.event
        this.formData.startDate = entity.start_date
        this.formData.endDate = entity.end_date
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          const items = []
          Object.keys(this.formData.fishingSpots).forEach(key => {
            items.push(this.formData.fishingSpots[key].value)
          })

          fd.fishingSpots = items

          this.$store.dispatch('updateReservationEvent', fd).then(async response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-events'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
